'use client';

import { useState, useRef, useEffect } from 'react';
import Link from 'next/link';
import { usePathname } from 'next/navigation';

export default function MobileMenu() {
	const pathname = usePathname();
	const isActive = (path: string) =>
		pathname === path ? 'text-sky-500' : 'text-gray-800';

	const [mobileNavOpen, setMobileNavOpen] = useState<boolean>(false);

	const trigger = useRef<HTMLButtonElement>(null);
	const mobileNav = useRef<HTMLDivElement>(null);

	// close the mobile menu on click outside
	useEffect(() => {
		const clickHandler = ({ target }: { target: EventTarget | null }): void => {
			if (!mobileNav.current || !trigger.current) return;
			if (
				!mobileNavOpen ||
				mobileNav.current.contains(target as Node) ||
				trigger.current.contains(target as Node)
			)
				return;
			setMobileNavOpen(false);
		};
		document.addEventListener('click', clickHandler);
		return () => document.removeEventListener('click', clickHandler);
	});

	// close the mobile menu if the esc key is pressed
	useEffect(() => {
		const keyHandler = ({ keyCode }: { keyCode: number }): void => {
			if (!mobileNavOpen || keyCode !== 27) return;
			setMobileNavOpen(false);
		};
		document.addEventListener('keydown', keyHandler);
		return () => document.removeEventListener('keydown', keyHandler);
	});

	const handleLinkClick = () => {
		setMobileNavOpen(false);
	};

	return (
		<div className="md:hidden">
			{/* Hamburger button */}
			<button
				ref={trigger}
				className={`hamburger ${mobileNavOpen && 'active'}`}
				aria-controls="mobile-nav"
				aria-expanded={mobileNavOpen}
				onClick={() => setMobileNavOpen(!mobileNavOpen)}
			>
				<span className="sr-only">Menu</span>
				<svg
					className="w-6 h-6 fill-current text-gray-300 hover:text-gray-200 transition duration-150 ease-in-out"
					viewBox="0 0 24 24"
					xmlns="https://www.w3.org/2000/svg"
				>
					<rect y="4" width="24" height="2" rx="1" />
					<rect y="11" width="24" height="2" rx="1" />
					<rect y="18" width="24" height="2" rx="1" />
				</svg>
			</button>

			{/*Mobile navigation */}
			<nav
				id="mobile-nav"
				ref={mobileNav}
				className={`absolute top-full z-20 left-0 w-full px-4 sm:px-6 overflow-hidden transition-all duration-300 ease-in-out ${mobileNavOpen ? 'max-h-screen opacity-100' : 'max-h-0 opacity-80'
					}`}
			>
				<ul className="bg-white bg-opacity-90 px-4 py-2 border-x border-y">
					<li>
						<Link
							href="/"
							className={`font-medium hover:text-gray-200 px-4 py-3 flex items-center transition duration-150 ease-in-out ${isActive('/')}`}
							onClick={handleLinkClick}
						>
							Home
						</Link>
					</li>
					<li>
						<Link
							href="/about"
							className={`font-medium hover:text-gray-200 px-4 py-3 flex items-center transition duration-150 ease-in-out ${isActive('/about')}`}
							onClick={handleLinkClick}
						>
							About
						</Link>
					</li>
					<li>
						<Link
							href="/contact"
							className={`font-medium hover:text-gray-200 px-4 py-3 flex items-center transition duration-150 ease-in-out ${isActive('/contact')}`}
							onClick={handleLinkClick}
						>
							Contact
						</Link>
					</li>
				</ul>
			</nav>
		</div>
	);
}
